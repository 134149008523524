<template>
  <div ref="panel" style="width: 100%;height: 100%;">

  </div>
</template>
<script>
import * as echarts from 'echarts'

export default {
  props: {
    option: {
      type: Object,
      default () {
        return {}
      }
    },
    data: {
      type: Array,
      default () {
        return []
      }
    }
  },

  watch: {
    data (val) {
      this.init(val)
    }
  },

  mounted () {
    this.init(this.data)
  },

  methods: {
    init (data) {
      const myChart = echarts.init(this.$refs.panel)
      const option = {
        label: {
          position: 'outer',
          alignTo: 'edge',
          margin: 0,
          color: '#B1CEF4'
        },
        series: [
          {
            name: '面积模式',
            type: 'pie',
            radius: [6, 30],
            center: ['50%', '50%'],
            roseType: 'area',
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 2
            },
            data: data,
            label: { // 饼图图形上的文本标签
              normal: {
                position: 'outside' // 标签的位置
              }
            },
            labelLine: {
              length: 1,
              length2: 10
            }
          }
        ]
      }

      option && myChart.setOption(option)
    }
  }
}
</script>
